
export enum HARVEST_TYPE {
  HARVEST = 'HARVEST',
  CULLED = 'CULLED',
}

export enum FALLWILD_TYPE {
  TRAFFIC = 'TRAFFIC',
  POACHED = 'POACHED',
  DISEASE = 'DISEASE',
  FROST = 'FROST',
  NATURAL_DISASTER = 'NATURAL_DISASTER',
  PREDATOR = 'PREDATOR',
  STARVATION = 'STARVATION',
  MOWING = 'MOWING',
  TRAP = 'TRAP',
  OTHER = 'OTHER'
}
