export enum WsEvent {
  Ping = 'ping',
  Authenticated = 'authenticated',
  Error = 'error',
  Connect = 'connect',
  ConnectError = 'connect_error',
  ConnectTimeout = 'connect_timeout',
  //chat room
  ChatMessageSeen = 'chat-message-seen',
  // events
  EventUpdate = 'event-update',
  EventDelete = 'event-delete',
  EventStatusUpdate = 'event-status-update',
  EventStatusUpdateSimple = 'event-status-update-simp',
  EventParticipantCountChanged = 'event-participant-count-changed',
  EventAssigneePoiCreate = 'event-assignee-poi-create',
  EventAssigneePoiUpdate = 'event-assignee-poi-update',
  EventAssigneePoiDelete = 'event-assignee-poi-delete',
  EventChatMessageCreate = 'event-chat-message-create',
  // districts
  DistrictChatMessageCreate = 'district-chat-message-create',
  DistrictCreate = 'district-create',
  DistrictUpdate = 'district-update',
  DistrictDelete = 'district-delete',
  // map paths
  MapPathCreate = 'map-path-create',
  MapPathUpdate = 'map-path-update',
  MapPathDelete = 'map-path-delete',
  // pois
  PoiCreate = 'poi-create',
  PoiUpdate = 'poi-update',
  PoiDelete = 'poi-delete',
  // entries
  EntryCreate = 'entry-create',
  EntryUpdate = 'entry-update',
  EntryDelete = 'entry-delete',
  // entity members
  MemberAdded = 'member-added',
  MemberRoleUpdate = 'member-role-update',
  MemberRemoved = 'member-removed',
  MemberCheckedIn = 'member-checked-in',
  MemberCheckedOut = 'member-checked-out',

  // groups
  GroupUpdate = 'group-update',
  GroupDelete = 'group-delete',
  // users
  UserLocationUpdateClient = 'user-location-update-client',
  UserLocationUpdateServer = 'user-location-update-server',
  // custom marks
  CustomMarkAdded = 'custom-mark-added',
  CustomMarkUpdate = 'custom-mark-update',
  CustomMarkDelete = 'custom-mark-delete',
  // shots
  ShotAdded = 'shot-added',
  ShotUpdate = 'shot-update',
  ShotDelete = 'shot-delete',

  // achievements
  AchievementAdded = 'achievement-added',
  AchievementDelete = 'achievement-delete',

  // user
  UserStripeSubPurchase = 'user-stripe-subscription-purchase',
  UserStripeChargeSucceeded = 'user-stripe-purchase-succeeded',
  UserStripeChargePending = 'user-stripe-purchase-pending',

  // subzones
  SubzoneCreate = 'subzone-create',
  SubzoneUpdate = 'subzone-update',
  SubzoneDelete = 'subzone-delete',
  // tasks
  TaskCreate = 'task-create',
  TaskUpdate = 'task-update',
  TaskDelete = 'task-delete',
  //dog tracking
  DogTrackingServerUpdate = 'dog-tracking-server-update',
  DogTrackingUpdate = 'dog-tracking-update'
}
